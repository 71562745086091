import React from 'react'
import { NavLink } from 'react-router-dom'
import {
    // CalendarIcon,
    // ChartPieIcon,
    Cog6ToothIcon,
    // DocumentDuplicateIcon,
    // FolderIcon,
    // HomeIcon,
    // UsersIcon,
    // PaintBrushIcon,
    ArrowPathIcon,
    TrophyIcon,
    // CurrencyYenIcon
} from '@heroicons/react/24/outline'

import { postFianlistsSync } from '../api/judge'

import logo from '../assets/images/logo.png'

const navigation = [ 
    // { name: '数据面板',   href: '/dash',      icon: HomeIcon, current: true },
    // { name: '参赛作品',   href: '/entries',   icon: PaintBrushIcon, current: false },
    { name: '入围作品',   href: '/finalists', icon: TrophyIcon, current: false },
    
]
const pages = [
    // { name: '评委管理',   href: '/members',   icon: UsersIcon, current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar({sidebarCollapse, setSidebarCollapse}) {

    const [loading, setLoading] = React.useState('');
    const handleSyncEntries = () => {
        setLoading('animate-spin');
        postFianlistsSync().then(res => {
            if (res.code === 0) {
                alert('同步任务已启动, 请稍后刷新页面查看最新数据')
             } else {
                alert(res.msg);
             }
        }).catch(err => {
            alert('同步失败, 请检查网络连接')
        }).finally(() => {
            setLoading('');
        });
    }

    
    return (
        
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-purple-600 px-6">
            <div className="flex h-16 shrink-0 items-center">
                <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="Your Company"
                />
                <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : 'ml-2 text-white text-lg text-bold'}>好创意大赛</span>
            </div>
            <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                        <div className="text-xs font-semibold leading-6 text-purple-200">作品</div>
                        <ul className="-mx-2 space-y-1">
                            <li>
                                <button 
                                onClick={ handleSyncEntries }
                                className="w-full hover:text-white bg-blue-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-purple-200">
                                    <ArrowPathIcon className={`h-6 w-6 shrink-0 text-purple-200 group-hover:text-white ${loading} `} aria-hidden="true" />
                                    <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : ''}>同步作品</span>
                                </button>
                            </li>
                            {navigation.map((item) => (
                                <li key={item.name}>
                                    <NavLink
                                        to={item.href}
                                        className={({ isActive }) =>
                                            isActive ?
                                             "hover:text-white hover:bg-purple-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold bg-purple-700 text-white" :
                                             "hover:text-white hover:bg-purple-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-purple-200"
                                        }
                                        // className={classNames(
                                        //     item.current
                                        //         ? 'bg-purple-700 text-white'
                                        //         : 'text-purple-200 hover:text-white hover:bg-purple-700',
                                        //     'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                        // )}
                                        title={item.name}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current ? 'text-white' : 'text-purple-200 group-hover:text-white',
                                                'h-6 w-6 shrink-0'
                                            )}
                                            aria-hidden="true"
                                            title={item.name}
                                        />
                                        <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : ''}>{item.name}</span>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li className='hidden'>
                        <div className="text-xs font-semibold leading-6 text-purple-200">成员</div>
                        <ul className="-mx-2 mt-2 space-y-1">
                            {pages.map((page) => (
                                <li key={page.name}>
                                    <NavLink
                                        to={page.href}
                                        className={({ isActive }) =>
                                            isActive ?
                                            "text-purple-200 hover:text-white hover:bg-purple-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold bg-purple-700 text-white" :
                                            "text-purple-200 hover:text-white hover:bg-purple-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold "
                                        }
                                        // className={classNames(
                                        //     page.current
                                        //         ? 'bg-purple-700 text-white'
                                        //         : 'text-purple-200 hover:text-white hover:bg-purple-700',
                                        //     'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                        // )}
                                    >
                                        <page.icon 
                                            className={classNames(
                                                page.current ? 'text-white' : 'text-purple-200 group-hover:text-white',
                                                'h-6 w-6 shrink-0'
                                            )}
                                            aria-hidden="true"
                                            title={page.name}
                                            />
                                        
                                        <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : ''}>{page.name}</span>
                                        {/* <span className="truncate">{page.name}</span> */}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li className="mt-auto hidden">

                        <NavLink
                            href="/"
                            className={({ isActive }) =>
                                isActive ?
                                "-mx-2 text-purple-200 hover:text-white hover:bg-purple-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold bg-purple-700 text-white" :
                                "-mx-2 text-purple-200 hover:text-white hover:bg-purple-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold "
                            }
                        >
                            <Cog6ToothIcon
                                className="h-6 w-6 shrink-0 text-purple-200 group-hover:text-white"
                                aria-hidden="true"
                            />
                            <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : ''}>Settings</span>
                        </NavLink>

                    </li>
                </ul>
            </nav>

        </div>
    )
}