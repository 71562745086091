import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { validLicenseSettings } from './utils/api.js'
import router from './routes/app.js'
import { Enums, Categories } from './utils/settings.js'
import { getEnums } from './api/judge.js'
import { getCategories } from './api/judge.js'
import RouterGuard from '@/components/RouterGuard/index.jsx'

validLicenseSettings()

function App() {
  try {
    getEnums().then((res) => {
      for (let [key, value] of Object.entries(res)) {
        Enums[key] = value
      }
    })
    getCategories({ filter: { type: 'events' }}).then(res => {
      Categories.events = res.list
    })
  } catch (e) {}

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <RouterGuard>
        <RouterProvider router={router} />
      </RouterGuard>
    </React.Suspense>
  )
}

export default App
