/**
 * If the browser supports the crypto.randomUUID() function, use it. Otherwise, if the browser supports
 * the crypto.getRandomValues() function, use it. Otherwise, use a fallback function
 * @returns A random UUID
 */

export const warning = () => {
  const year = new Date().getFullYear()
  console.clear()
  console.error('\n'
    .concat('%c 警告! ')
    .concat('\n', '%c 此功能为浏览器专供开发者使用。若某人让您在此复制粘贴内容，为欺诈行为，会使对方获权进入您的帐户! ')
    .concat('\n', ' 详情参考: https://developer.mozilla.org/zh-CN/docs/Glossary/Cross-site_scripting '),
  'color: #f00; background: #000; padding:5px 0; font-size: 24px;',
  'color: #ff0; background: #000; padding:5px 0; font-size: 16px;'
  )

  console.log('\n'
    .concat(`%c ©2014-${year} `)
    .concat('%c Joe#wzhu.net ')
    .concat('%c https://www.zhuweiwei.cn ', '\n')
  , 'color: #000; background: #0f0; padding:5px 0; font-size: 16px;'
  , 'color: #0f0; background: #000; padding:5px 0; font-size: 16px;'
  , 'color: #000; background: #0f0; padding:5px 0; font-size: 16px;'
  )
}

export const randomUUID = () => {
  if (typeof (window.crypto.randomUUID) != 'undefined') {
    return window.crypto.randomUUID()
  }
  if (typeof (window.crypto.getRandomValues) != 'undefined') {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    // eslint-disable-next-line no-mixed-operators
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }
  return 'xxxxxxxx-xxxx-4444-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#examples
export const RFC3986EncodeURIComponent = (str) => {
  // return encodeURIComponent(str);
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16)
    .toUpperCase()}`)
  //encodeURIComponent not encode A-Z a-z 0-9 - _ . ! ~ * ' ( )
}

/**
 * It takes an object and returns a new object with the same keys and values, but sorted by key
 * @param obj - The object to sort
 * @returns A function that takes an object as an argument and returns a sorted object.
 */
export const ksort = (obj) => {
  var keys = Object.keys(obj).sort(),
    sortedObj = {}
  for (let i in keys) {
    sortedObj[keys[i]] = obj[keys[i]]
  }
  return sortedObj
}

/**
 * If the number is a single digit, add a zero to the front of it
 */
export const formatZeroNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

//格式化价格, 千分位, 小数点后两位
export const formatPrice = (price) => {
  price = parseInt(price / 100)
  return price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}

//时间戳 转成 本地格式 时间
//YYYY-MM-DD HH:MM:SS
//YYYY年MM月DD日
export const formatTimestampToDate = (timestamp, format) => {
  const d = new Date(1000 * timestamp)
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const day = d.getDate()
  const hour = d.getHours()
  const minute = d.getMinutes()
  const second = d.getSeconds()

  var formateArr = ['Y', 'M', 'D', 'h', 'm', 's']
  var returnArr = []

  returnArr.push(year)
  returnArr.push(formatZeroNumber(month))
  returnArr.push(formatZeroNumber(day))
  returnArr.push(formatZeroNumber(hour))
  returnArr.push(formatZeroNumber(minute))
  returnArr.push(formatZeroNumber(second))

  for (let i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i])
  }
  return format
}

export const calculateAge = (birthday) => { // birthday is a date of seconds (Unix timestamp)
  var ageDifMs = Date.now() - birthday * 1000
  var ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

/**
 * @description 深拷贝
 * @param {Object} obj 对象
 * @returns {Object}
 */
export const deepCopy = (obj) => {
  const newObj = Array.isArray(obj) ? [] : {}
  for (const key in obj) {
    if (typeof obj[key] === 'function') {
      // 如果是函数方法，则手动复制到新对象中
      newObj[key] = obj[key]
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      // 如果是对象，则递归进行深拷贝
      newObj[key] = deepCopy(obj[key])
    } else {
      // 如果是基本类型，则直接复制到新对象中
      newObj[key] = obj[key]
    }
  }
  return newObj
}
