
import React from 'react'
import { createHashRouter, Navigate } from 'react-router-dom'
import useToken from '@/utils/useToken.js'

import pageLoader from '../layout/Loader.jsx'
import Layout from '../layout/index.jsx'
import Error404 from '../pages/Error/404.jsx'

const Finalists = React.lazy(() => import('../pages/Finalists/index.jsx'))
const Signin = React.lazy(() => import('../pages/Auth/Signin.jsx'))
const Signup = React.lazy(() => import('../pages/Auth/Signup.jsx'))

const router = createHashRouter(
  [
    {
      path: '/auth', loader: pageLoader, errorElement: <Error404 />, children: [
        { index: true, element: <Signin />, loader: pageLoader },
        { path: 'signin', element: <Signin />, loader: pageLoader },
        { path: 'signup', element: <Signup />, loader: pageLoader }
      ]
    },
    {
      path: '/', element: <AuthComponent><Layout /></AuthComponent>, loader: pageLoader, errorElement: <Error404 />, children: [
        { index: true, element: <Finalists />, loader: pageLoader },
        {
          path: 'finalists', element: <Finalists />, loader: pageLoader, children: [
            { index: true, element: <Finalists />, loader: pageLoader },
            { path: 'list', element: <Finalists />, loader: pageLoader }
          ]
        }
      ]
    }
  ]
)

function AuthComponent({ children }) {
  const { getToken } = useToken()
  return getToken() ? children : <Navigate to={'/auth/signin'}></Navigate>
}

export default router